import React from "react";
import ReactDOM from "react-dom/client";
import {ExperienceItem} from "./ExperienceItem";
import {EducationItem} from "./EducationItem";

export function Education () {
    return (
        <section id="experience" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-4 lg:scroll-mt-24">
            <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-neutral-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
                <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Education</h2>
            </div>
            <div>
                <ol className="group/list">
                    <EducationItem
                        schoolName="United States Military Academy at West Point"
                        crest="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/U.S._Military_Academy_Coat_of_Arms.svg/300px-U.S._Military_Academy_Coat_of_Arms.svg.png"
                        degree="Bachelor of Science - BS"
                        major="Mechanical Engineering"
                        activitiesSocieties="Excel Scholar, Cyber Tech, Black & Gold Leadership, Research, SPEAR, CLD STEM Outreach, Dean's List"
                        description="Minor in Aeronautical Engineering, Cybersecurity"
                    />
                </ol>
            </div>
        </section>
    )
}